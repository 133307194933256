@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: "Shne Breit";
  src: url('../fonts/572a2d40c1cb6e1049aa231ee432e834.woff2') format('woff2'),
    url('../fonts/572a2d40c1cb6e1049aa231ee432e834.woff') format('woff'),
    url('../fonts/572a2d40c1cb6e1049aa231ee432e834.ttf') format('truetype');
  font-display: swap;
}



// Desktop smll

@media only screen and (max-width: 1536px) {
  .Grec--machine-card {
    .card-img-container {

  }
  }

}


@media only screen and (min-width: 601px) and (max-width: 1800px) {
  .machine--card--aplication--group {

    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    gap: 0.5rem !important;

    .machine--card--aplication {
      width: calc(100% - 40px) !important;
    }
  }
}

// Mobile


@media only screen and (max-width: 600px) {


  // navbar
  .search-input {

    width: calc(92dvw - 30px)!important;
   
    font-size: 18px!important;
 
}



.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 80dvh!important;
  li{
    p{
      font-size: 14px!important;
    }
  }
}
// footer
.body-links{
  text-decoration: none!important;
  color: white!important;
  a{
    text-decoration: none!important;
    color: white!important;
    p{
      text-decoration: none!important;
      color: white!important;
    }
  }
}

.Grec--footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .footer-main {
    display: flex;
    flex-wrap: wrap
  }

  .flex {
    
    flex-direction: column;
  }
}

// Cookie
.Grec--cookie {
  
  right: 0px!important;
 
  margin: 15px!important;
}
// hero
.video-button {
 
  bottom: 30px!important;
 
}
.video-social {

  top: 15px;
  left: 5px;

}
// home 

.ifram-dynamic-info-bar{
  display: none;
}
.grec-made-in-italy-grid{
  display: flex;
}


.Grec-subtitle-container p {
  font-size: 1.2rem!important;

}
.Grec--card--groups {
  display: flex;
  flex-direction: column;
 .Grec--card {
    margin: 2.5px;
    cursor: pointer;
    width: calc(92dvw - 30px)!important;
    height: 400px;
a{
  color: white!important;
  &:visited{
    color: white!important;
  }
  &:hover{
    color: white!important;
  }
  &:visited{
    color: white!important;
  }
}
 
 
}
}
// page

.Grec-title-container {
  margin: 0 3px 35px!important;
  h1 {
    
    line-height: 1!important;
    font-size: 2rem!important;
  }
  p{
    font-size: 18px!important;
    margin: 0 15px!important;
    text-align: left!important;
  }
}

.Grec-content-container {
  margin: 5% 15px 5%!important;

}


// Prodotto
.Grec--machine--main {
  margin: 0 15px!important;
  border-radius: 7.5px!important;
}

.Grec--machine--header {
  margin: 0 15px!important;
h2{
  font-size: 1rem!important;
}
  button {
  
    font-size: 1rem!important;

  }
  .Grec--machine--main {
  

    margin: 0 15px!important;
    border-radius: 7.5px!important;
}}
.machine--body {
  margin: 0 5px!important;

}
.Grec--machine--info {
  position: unset!important;
  padding:5px;
  .Grec--machine--description{
    font-size: 1rem;
    br{
      display: none;
    }
  }
}
.machine--card--aplication--group {

  flex-direction: column;
.machine--card--aplication {
   
  width: calc(100% - 40px) !important;

}
}

.Grec--machine--main{
  .Grec--machine-control{
    z-index: 51!important;
    display: none;
  }
  .sketchfab-embed-wrapper{
    z-index: 50!important;
  }
}



// Download





// .document-list {
//   .documents-container {
 
//     flex-wrap: wrap!important;
//     flex-direction: column!important;
//   }
// }



// Applicazioni
.p-sector-detail{
  flex-direction: column!important;
  a{
    padding: 3px!important;
  }
  span:last-of-type{
    // line-height: ;
  margin-top: 15px;

  }
}
// Discalimer
.discaimer-content {
  flex-direction: column!important;
 button {
    width: calc(100% - 15px)!important;
  } }
}

html {
  scroll-behavior: smooth;
}




*{
  font-smooth: always;
}

.ShneBreit {
  font-family: "Shne Breit";
  font-size: 1.3rem;
  font-weight: bold;
}

.Product-Body{
  // background:  #333!important;

  // margin-left: -1px;


  background: linear-gradient(144deg, rgb(12 12 12 / 88%), rgba(29, 29, 29, 0.122));
  margin: 30px;
  margin-top: 106px;
  border-radius: 15px;

}

body {
  font-family: 'Inter', sans-serif;

  background: linear-gradient(90deg, #E44949, #1E1E1E);
}


.lavora-con-noi{
  display: flex;





  justify-content: center;

  form{
    margin-left: 15px;
    padding: 15px;
    background-color: white;
    width: 630px;
    border-radius: 7.5px;
  
    input, textarea{
      width: 100%;
      min-width: 600px;
      max-width: 600px;
      padding: 15px;
    
    }
    textarea{
      min-width: 600px;
      max-width: 600px;
 
    }
    button{
      width: 100%;
      padding: 15px;
    }
  }
}




.lavori-list {
  width: 70%;
  min-width: 700px;


  .lavoro-item {
    // border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;

    .lavoro-header {
      background-color: rgba(0, 0, 0, 0.22);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
     
      cursor: pointer;
      transition: background-color 0.3s ease;

      h2 {
        margin: 0;
        font-size: 1.2rem;
      }

      svg {
        font-size: 1.5rem;
      }

      &:hover {
        background-color: rgba(27, 27, 27, 0.22);
      }
    }

    .lavoro-content {
      padding: 15px;
      animation: slideDown 0.3s ease;
      background-color: #f8f8f8;
      p {
        margin: 5px 0;
      }
    }
  }
}


@media (max-width: 1200px) {
  .work-con-noi{
    flex-wrap: wrap;

    .lavori-list {
      width: 100%;
      min-width: unset;
  
      .lavoro-item {
  
      }
    }
  }
}


@media (max-width: 600px) {

  .work-con-noi{

    .lavori-list {
  
      .lavoro-item {
        .lavoro-header{
          h2{
            font-size: 0.9rem;
            font-weight: 500;
          }
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media (max-width: 500px) {

  .work-con-noi{

    .lavori-list {
  
      .lavoro-item {
        .lavoro-header{
          span{
            display: none;
          }
        }
      }
    }
  }
}




@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px;
    opacity: 1;
  }
}




.body--max--control {
  max-width: 1900px;
  margin: 0 auto;
}



.margin-top {
  margin-top: 150px;
}

.page--container {
  margin-top: 124px;
}


@keyframes bounceIn2 {
  0% {
    transform: scale(0.96);
   
  }
  70% {
    transform: scale(1.01);

  }
  100% {
    transform: scale(1);
  }
}
.Grec--button-filter{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
  
  
  button {
    padding: 1px 15px;
    width: 19%;
    border-radius: 15px!important;
    font-size: 12px!important;
    margin: 2.5px;
    border: none;
    background-color: rgba(0, 0, 0, 0.22);
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);
    color: white;

    border-radius: 15px;
    font-size: 1rem;
    font-family: "Shne Breit";
    font-weight: 600;
    // aspect-ratio: 2/3; VERGOGNIATI
    scale: 0.99;

    h3{
      margin-bottom: 0px !important;
      height: 48px;
    }
  }

  button.active {
    background:linear-gradient(135deg, rgba(12, 12, 12, 0.2549019608) 0%, rgba(0, 0, 0, 0.7725490196) 100%);
    color: white;
    scale: 1;

   animation: bounceIn2 0.6s ease-out;
  }

  button:hover {
    background-color: rgba(0, 0, 0, 0.301);

  }

  button:active {
    scale: 0.99;
  }
}

@media (max-width: 991px) {
  .Grec--button-filter{
      button{
        aspect-ratio: 3/5;
        padding: 15px;

        h3{
          font-size: 0.9rem;
          height: 30px;
        }
      }
  }
}
@media (max-width: 767px) {
  .Grec--button-filter{
      button{
        aspect-ratio: 5/3;
        padding: 8px;
        .icon-category{
          display: none;
        }
        h3{
          font-size: 0.8rem;
          height: auto;
          margin: 0;
          letter-spacing: 1px;
        }
      }

  }
}
@media (max-width: 575px) {
  .Grec--button-filter{
    gap: 4px;
      button{
        width: calc(50% - 7px);
        aspect-ratio: unset;
        height: 60px;
        padding: 8px;
        .icon-category{
          display: none;
        }
        h3{
          font-size: 0.8rem;
          height: auto;
          margin: 0;
          letter-spacing: 1px;
        }
      }
    & > button:last-child{
      width: 100%;
    }

  }
}







#vitoz{

}
.Grec--machine-card {
a{
  text-decoration: none;
  color: black;
}
  margin: 7.5px;
  background-color: white;
  padding: 40px 15px 15px;
  border-radius: 7.5px;

  div {
    h2 {
      line-height: 0;
      font-family: "Shne Breit";
    }

    p {
      line-height: 2.5px;
    }

    span {
      background-color: #333;
      padding: 5px 15px;
      border-radius: 7.5px;
      color: white;
    }
  }


  .card-img-container {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 7.5px 7.5px 0 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.Grec--machine-card:active {
  scale: 0.99;

}



.Grec--cookie {

  max-width: 600px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 117;
  background-color: #434343;
  color: white;
  border-radius: 15px;

  h2 {
    font-size: 1.05em;
    margin: 15px;
    

  }

  p {
    font-size: .9em;
    line-height: 1.5;
    margin: 15px;

  }

  hr {
    opacity: 0.2;

  }

  .Grec--cookie--buttons {
    display: flex;
    margin: 15px;
    justify-content: space-between;

    div {
      button {
        margin-right: 5px;
      }
    }

    button {
      padding: 7.5px;
      border-radius: 5px;
      border: 0.1px solid white;
      font-size: 0.9rem;

    }

    button:active {
      scale: 0.9;
    }

  }
}



.Grec--machine--header {
  display: flex;
  justify-content: space-between;
  margin: 0 8%;
  align-items: center;

  a {
    color: white
  }

  h2 {
    color: white;
    line-height: 1;
    margin: 0;
    font-weight: 500;
  }

  button {
    padding: 10px;
    border-radius: 7.5px;
    background-color: #ffffff0d;
    border:none;
    color: white;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  button:active {
    scale: 0.95;
  }
}


.Grec--machine--description {
  max-width: 500px;
  font-size: 1rem;
}

.Grec--machine--details-container{
display: flex;
justify-content: space-between;
margin: 0 8%;
.Grec--machine--series-details{
  justify-content: center;
  display: flex;
  flex-direction: column;
  .Grec--machine--series-desc{
    background-color: rgb(0 0 0 / 22%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
padding: 20px;
margin-bottom: 15px;
border-radius: 7.5px;
   p{
    width: 500px;
    color: white;
   }
   strong{
  font-size: 1.2rem;
  font-family: "Shne Breit";
   }
  }
}
}




.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}


.active, .accordion:hover {
  background-color: #ccc;
}


.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}


.machine--card--aplication--group{
  display: flex;
  flex-wrap: wrap;
  // overflow-x: auto;
  // width: 2000px;
  // scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  .machine--card--aplication{
h3{
  font-family: "Shne Breit";
  line-height: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.068)
}
 background-color: rgb(0 0 0 / 22%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 15px;
    margin: 5px;
    width:22%;
    border-radius: 7.5px;
  }

}


.Grec--machine--details{
  background-color: rgb(0 0 0 / 22%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
 
  width: 600px;
border-radius: 7.5px;
position: relative;
img{
margin: 0 auto;
width: 1200px;
margin-bottom: -4px;
}

.details-2{
width: 700px!important;

}
.content-2{
  max-width: 700px!important;
  
p{
  width: 100%!important;
}
}
.Grec--machine--content{
  max-width: 300px;
  position: absolute;
  top: 15px;
  left: 15px;
  color: white;
 h2{
  font-family: "Shne Breit";
 }
}
a{
  color: black;
  text-decoration: none;
}
button{
  position: absolute;
  bottom: 0px;
  max-width: 560px;
  margin: 15px;

  padding: 15px 20px;
    margin-right: 5px;
    border-radius: 7.5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
 
    font-family: "Shne Breit";
    font-weight: bold;

}
}


.machine--body {
  margin: 0 8%;
  color: white;

  h2 {
    font-size: 2rem;
    padding-left: 0.5rem;

  }

  // button:first-of-type {
  //   background-color: white;
  //   color: black;
  // }

  button {
    padding: 15px;
    margin: 5px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    // background-color: rgb(0 0 0 / 22%);
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);
    color: white;
    font-size: 1.3rem;
  }

  .group-application{

  }

  .application-line{
    display: flex;
    justify-content: space-between;
        background-color: rgb(0 0 0 / 22%);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin-bottom: 5px;
    border-radius: 12px;
  
  }
  .application{
    display: flex;
    flex-direction: column;
    align-items: flex-end
  }


}

.Grec--machine--info {
  position: absolute;
  left: 15px;
  top: 15px;
  line-height: 1;
  color: white;


  h1 {
    display: inline;
    font-size: 3rem;

    font-family: "Shne Breit";

  }

  p {
    margin-top: 7.5px;

    &:first-of-type {
      font-size: 2rem;
      margin: 0;
    }

    font-size: 1.3rem;
  }

}



.Grec--machine-control {
  position: absolute;
  background-color: white;
  padding: 7.5px;
  border-radius: 7.5px;
  cursor: pointer;

  &:hover {
    background-color: rgb(236, 236, 236);

    .Grec--machine-icon {
      scale: 1.05;
    }
  }

  &:active {
    scale: 0.95;

  }
}




@media (max-width: 1600px) {
  .Grec--machine--details-container .Grec--machine--series-details {
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    
    .Grec--machine--series-desc{
      margin: 0;
    }
  }
}

@media (max-width: 1470px) {
  .Product-Body{
    overflow: hidden;
  }
  .Grec--machine--details-container {
    flex-wrap: wrap;

  }
  .Grec--machine--content{

  }

  .Grec--machine--details-container  .Grec--machine--details{
    width: 100%;
    img{
      padding-left: 25%;
      width: 100%;
    }
  }

  .Grec--machine--details .Grec--machine--content{
    max-width: 400px;
  }

  .Grec--machine--details-container .Grec--machine--series-details {
    margin-top: 16px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    
    .Grec--machine--series-desc{
      margin: 0;
      width: calc(50% - 8px);
      aspect-ratio: 3/1;
      display: flex;
      align-items: center;

      p{
        width: 100%;
      }
    }
  }
}
@media (max-width: 1200px) {
  .Grec--machine--details-container .Grec--machine--series-details {
    .Grec--machine--series-desc{
      aspect-ratio: 2/1;
    }
  }
}

@media (max-width: 991px) {
  .Grec--machine--details-container .Grec--machine--series-details {
    flex-wrap: wrap;

    .Grec--machine--series-desc{
      aspect-ratio: unset;
      width: 100%;
    }
  }

  .Grec--machine--details-container {
    margin: 0 5px!important;
    flex-direction: column;
    position: unset!important;
    .Grec--machine--details {
  
     
      
      position: relative;
      height: 600px;
      margin-bottom: 15px;
      img {
            position: absolute;
            padding: 0;
            width: 98vw!important;
            margin-bottom: 0!important;
            bottom: 0!important;
        }
    }
    .Grec--machine--series-desc {
      margin: 5px 5px;
      p {
        width: 90%!important;
        color: white;
      }
    }
  }
}

@media (max-width: 400px) {
  .Grec--machine--details-container .Grec--machine--series-details {
    flex-wrap: wrap;


    .Grec--machine--series-desc{
      aspect-ratio: unset;
      width: 100%;
    }
  }

  .Grec--machine--details-container {
    .Grec--machine--details {
        img {
          position: absolute;
          padding: 0;

          margin-bottom: 0!important;
          bottom: 0!important;
          left: -20%;
          width: 100dvw;
      }
    }
  }

  .Grec--machine--details-container {
    margin: 0 5px!important;
    flex-direction: column;
    position: unset!important;
    .Grec--machine--details {
      height: 500px;
      padding-bottom: 100px;
    }
    .Grec--machine--content{
      p{
        font-size: 0.9rem;
      }
    }
  }
}






.overlay {

  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

iframe {
  user-select: none;

}

.Grec--machine-icon {
  font-size: 20px;
  margin-bottom: -2.5px
}

.Grec--machine-control:nth-of-type(2) {
  left: 15px;
  bottom: 15px;
  //    border-bottom-left-radius: 15px;
  //    border-top-right-radius: 15px;
}

.Grec--machine-control:nth-of-type(3) {
  right: 15px;
  bottom: 15px;
  // border-bottom-right-radius: 15px;
  // border-top-left-radius: 15px;
}




.Grec--machine--main {
  position: relative;
  // background-color: #ffffff17;
  background-color: rgb(0 0 0 / 22%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  margin: 0 2%;
  border-radius: 15px;


}



.flex {
  display: flex;
  justify-content: space-between;
}

.Grec--footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  margin: 0 auto;
  margin-bottom: 0;
  padding: 15px 15px 65px;

  background-color: #181818;
  color: white;


  .footer-contact{
    p{
      font-size: 1.2rem;
      line-height: 0.5;
    }
   
  }
.footer-interactiv{
  background: linear-gradient(314deg, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 4%) 100%);
  /* width: calc(98% - 60px); */
  border-radius: 15px;
  padding: 15px 15px 1px;
  display: flex;
  flex-direction: row-reverse;
  font-size: 1.2rem;
  gap: 15px;
  height: 42px;
text-align: left;
.contact-item{

}
svg{
  // color: #e01d1f
  opacity: 0.7;
}
  a{
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    color: white;
    text-decoration: none;
    margin-bottom: 15px;
   
  }

}
  .footer-main {

    background: linear-gradient(144deg, #0c0c0c 0%, rgba(29, 29, 29, 0.1215686275) 100%);

    width: calc( 98% - 60px);
    border-radius: 15px;
    padding: 15px 30px;
    // margin: 15px 10% 15px 15px;
    margin: 15px 0 15px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
    justify-content: space-between;

  .footer-logo-container{
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    div{
      margin-top: 15px;
    }
   
    a{
     
      color: white;
    }
  }
    img {
      height: 61px;
    }

    p {
      font-size: 1.2rem;
      cursor: pointer;
      color: white;
      font-family: "Shne Breit";
      line-height: 0.45;
    }


  
  }

  .footer-down {
 
    background: linear-gradient(127deg, #030303cc 0%, rgba(29, 29, 29, 0.1215686275) 100%);
    width: calc(98% - 60px);
    border-radius: 15px;
    padding: 15px 30px;
  }

}

@media (max-width: 991px) {
  .footer-main {
    align-items: center;
    width: 100%;

    .footer-logo-container{
      width: fit-content;
      padding-left: 0px!important;
    }

    &> div:nth-child(2), &> div:nth-child(3){
      width: 30%
    }

    img {
      height: 61px;
    }

    p {
      font-size: 1.2rem;
      cursor: pointer;
      color: white;
      font-family: "Shne Breit";
      line-height: 0.45;
    }
  }
  .footer-down{
    .footer-info-legal{
      justify-content: space-between;
      p:first-child{
        width:40%
      }
      p:nth-child(2){
        width: 50%;
        text-align: end;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer-main {
    align-items: center;
    width: calc(100% - 45px)!important;
    padding: 16px!important;

    .footer-logo-container{
      a > img{
        width: 20dvw;
        height: auto;
      }
      svg{
        width: 24px;
        height: 24px;
      }
      div{
        display: flex;
        gap: 8px;
      }
    }

    .body-links{
      padding: 0;

      a{
        font-size: 14px;
        p{
          font-size: 16px;
          margin: 0;
          line-height: 18px;
        }
        padding: 4px;
        display: block;
      }
    }

  .footer-logo-container{
      width: fit-content;
      padding-left: 0px!important;
    }

    &> div:nth-child(2){
      width: 30%
    }
    &> div:nth-child(3){
      width: 34%;
    }
  }
  .footer-interactiv{
    a{
      font-size: 1rem;

      svg{
        width: 24px;
        height: 24px;
      }
    }
  }

}
@media (max-width: 576px) {
  .footer-main {
    padding-bottom: 22px !important;
    gap: 8px;

    .footer-logo-container{
      width: 100%;
      a{
        width: 50%;
        max-width: 140px;
      }
      a > img{
        width: 100%;
        height: auto;
      }
      svg{
        width: 28px;
        height: 28px;
      }
      div{
        display: flex;
        gap: 12px;
      }
    }

    &> div:nth-child(2), &> div:nth-child(3){
      width: 100%
    }
    &> div:nth-child(4){
      display: flex;
      justify-content: center;
      gap: 12px;
      width: 100%;
      a{
        max-width: 30%;
        display: block;
      }
      p{
        width: 100%;
        text-align: center;
        line-height: 18px;
        font-size: 14px;
        margin: 6px 0;
      }
    }

    .body-links{
      a{
        text-align: center;
        padding: 6px 0;
      }
    }
    .footer-interactiv{
      width: 100%;
      justify-content: center;
    }

  }

  .footer-down{
    .footer-info-legal{
      justify-content: space-between;
      p{
        width:100% !important;
        text-align: start !important;
      }

    }
    .flex{
      p{
        text-align: center;
      }
    }
  }


}



// // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  GREC - Navbar


.Active {
  background-color: #333;
  color: white;

  &:hover {
    background-color: #333 !important;
    color: white;
  }
}
#last-a{
  margin-right: 15px;
}
.Grec--navbar-desktop-bottom {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0px 14px #00000012;
  padding: 5px 2px;
  z-index: 115;
  font-family: "Shne Breit";
  margin: 0;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  width: 100%;
  max-width: 1350px;
  border-radius: 15px;
// a:last-of-type{
//   margin-right: 8px;
// }
  a, .products-dropdown {
    text-decoration: none;
    color: black;
    padding: 15px;
    border-radius: 8px;
    font-weight: bold;
    display: block;

    img {
      height: 21px;
      margin-left: -15px;
      margin-bottom: -5px;
    }
  }

  .Active {
    background-color: #000000;
    color: white!important;
    
      a{
        p{
          color: white!important;
        }
      }
    }
  

  p {
    margin: 0;
  }

  .products-dropdown {
    position: relative;

    a {
      padding: 0;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      bottom: 100%;
      left: 0;
      background-color: #FFFFFF;
      min-width: 250px;
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
      z-index: 1;
      border-radius: 8px;
      padding: 10px 0;

      a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        font-weight: bold;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }

    &:hover .dropdown-content {
      display: block;
    }
  }

  
}
/* Responsive adjustments */
@media (max-width: 1400px) {
  .Grec--navbar-desktop-bottom {
    font-size: 0.9rem;
    flex-wrap: wrap;
    width: 90%;
    padding: 12px;
  }
  
  .Grec--navbar-desktop-bottom a {
    padding: 10px 10px 6px 10px;
  }
}
@media (max-width: 1200px) {
  .Grec--navbar-desktop-bottom {
    font-size: 0.8rem;
    width: 95%;
    padding: 8px;
  }
  .Grec--navbar-desktop-bottom > a:first-child {
    display: none;
  }
  .Grec--navbar-desktop-bottom a {
    padding: 6px 10px 4px 10px;
  }

}

@media (max-width: 991px) {
  .Grec--navbar-desktop-bottom {
    display: none;
  }

}

.Grec--navbar {

  position: fixed;
  top: 0;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  box-shadow: 0 0px 20px #00000008;
  padding: 15px;
  z-index: 115;

  img {
    height: 35px;
    user-select: none;
    // margin-left: -70px;
  }


}

.Grec--navbar--icon {
  cursor: pointer;
  background-color: #E3E3E3;
  font-size: 30px;
  border-radius: 5px;

  &:active {
    scale: 0.90;
  }
}


.Grec--navbar--menu {
  position: fixed;
  top: 0;
  z-index: 115;
  background-color: #FFFFFF;
 
width: 400px;
  height: 100vh;
  box-shadow: 0 0px 20px #00000045;
  
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  // animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);



  &.slide-in {
    animation-name: slide-in;
  }

  &.slide-out {
    animation-name: slide-out;
  }



  .Grec--navbar--icon {


    margin: 15px
  }

  .Grec--navbar--interaction {
    a {
      display: flex;

    }
  }

  a {
    color: black;
    text-decoration: none;

    p {
      font-family: "Shne Breit";
      width: calc(100% - 15px);
      margin: 0;
      font-size: 1.2rem;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      border-bottom: 1px solid black;
      font-weight: 600;
      display: flex;
      justify-content: space-between;

      span:last-of-type {


        svg {
          margin-bottom: -2.5px;
        }
      }

      &:hover {
        background-color: #3333331a;
      }

    }

  }

}

.search-container {
  z-index: 110;
  position: absolute;
  position: fixed;
  top: 69Px; 
  left: 0;
  width: 100%;
  background-color: white;
  padding: 15px;
  // box-shadow: 0 0px 20px rgba(0, 0, 0, 0.1);
 
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: slide-down;
}

.search-container.slide-up {
  animation-name: slide-up;
}

.search-input {
  font-family: "Shne Breit";
 width: calc(98dvw - 30px);
  padding: 10px;
  font-size: 25px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 600px;
  overflow-y: auto;

}

.search-results li {

  padding: 10px;
  border-bottom: 1px solid #ccc;
  
}

.search-results li a {
  text-decoration: none;
  color: black;
 
}

.search-results li p {
  // display: flex;
  // justify-content: space-between;
  font-weight: bold;
  font-family: "Shne Breit";
  strong{
    opacity: 0.5;
    font-weight: normal;
  }
 
}
.search-results li a:hover {
  background-color: #f0f0f0;
}


@keyframes slide-in {
  from {
    transform: translateX(-100%);
    //   opacity: 0;

  }

  to {
    transform: translateX(0);
    //   opacity: 1;
  }
}


@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
    //   opacity: 1;
  }

  to {
    transform: translateX(-100%);
    //   opacity: 0;
  }
}




::-webkit-scrollbar {
  width: 18px;
  height: 15px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.22);

 
}



.scroll-down::-webkit-scrollbar-thumb {
  background: linear-gradient(#e4494900, #333, #333);
  border-radius: 15px;
  border: 2px solid #ffffff;
  transition: background 0.3s ease, border 0.3s ease;
}

.scroll-up::-webkit-scrollbar-thumb {
  background: linear-gradient(#333, #333, #e4494900);
  border-radius: 15px;
  border: 2px solid #ffffff;
  transition: background 0.3s ease, border 0.3s ease;
}

::-webkit-scrollbar-track {
  background: #ffffff;

}

// ::-webkit-scrollbar-thumb {
//   // background: #333333;
//   background: linear-gradient(#e4494900, #333, #333);
//   border-radius: 15px;
//   border: 2px solid #ffffff;
// }

/* Firefox */
* {
  // scrollbar-width: thin;
  // scrollbar-color: #333333 #ffffff;
}

/* Per Internet Explorer e Edge */
// body {
//   -ms-overflow-style: none;
// }

// body::-ms-scrollbar {
//   width: 15px;
//   height: 15px;
// }

// body::-ms-scrollbar-track {
//   background: #ffffff;
//   border-radius: 7.5px;
// }

// body::-ms-scrollbar-thumb {
//   background: #333333;
//   border-radius: 7.5px;
//   border: 3px solid #ffffff;
// }



// About

.Grec-title-container {
  color: white;
  margin: 0 13%;
  text-align: center;

  h1 {
    font-family: "Shne Breit";
    line-height: 0;
    font-size: 3rem;
  }

  p {
    font-size: 1rem;
    max-width: 800px;
    margin: 0 auto;
  }

  margin-bottom: 35px;

  a {
    color: white;
  }
}
.video-container-about {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: -41px;
  display: flex;
  justify-content: center;
  .video-about {
    width: calc(100% - 11px);
    border-radius: 7.5px;
    margin: 5px;
  }
}


.Grec--card-title {

  align-items: center;
  margin: 0 5% 20px;
.vitto{
    display: flex;
    align-items: center;
    color: white;
    justify-content: space-between;
    width: 100%;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    
    span {
      font-size: 1rem;
      color: #666;
      margin-left: 10px;
    }
  }
}

.mute-button {
  position: absolute;
  top: 16px;
  left: 28px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mute-button:hover {
  background: rgba(0, 0, 0, 0.7);
}



.video-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  font-size: 24px;
  padding: 10px;
  z-index: 1;
  pointer-events: none;
  animation: fadeInOut 1s ease-in-out;
  animation-fill-mode: forwards;
}

.play-icon {
  display: block;
}

.pause-icon {
  display: block;
}

.Grec-content-container{
  background: linear-gradient(144deg, #0c0c0c, rgba(29, 29, 29, .122));
  padding: 42px;
  border-radius: 15px;
  margin: 2% 13% ;
  height: fit-content;
  display: block;


  .img-container{
    // margin-bottom: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: block;

    img{
      object-fit: cover;
      object-position: center;
      // height: 200px;
      // width: 50%;
      border-radius: 7.5px;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  h2{
    font-family: "Shne Breit";
    font-size: 2rem;
  }
  p{
font-size: 1.4rem;
  }
  color: white;
}
@media (max-width: 768px) {
  .Grec-content-container{
    p{
      font-size: 1.1em;
      line-height: 1.6em;
    }
  }
}

  
  
  

@keyframes fadeInOut {
  0% {
    opacity: 1;

  }

  50% {
    opacity: 1;

  }

  100% {
    opacity: 0;
  }
}


// Home

.grec-made-in-italy {
  padding: 2rem;
  // background-color: rgba(0, 0, 0, 0.22);

  background: rgba(0, 0, 0, 0.22);

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  color: white;
  // margin: 2rem 5%;
  margin: 2rem 10px;
  width: 95%;

  h2 {
    font-family: "Shne Breit", sans-serif;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 2rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .product-selector {
    list-style-type: none;
    padding: 0;

    li {
      cursor: pointer;
      padding: 0.75rem 1rem;
      margin-bottom: 0.5rem;
      border-radius: 8px;
      transition: background-color 0.3s ease;
      font-family: "Shne Breit", sans-serif;

      &:hover, &.active {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    @media (max-width: 768px) {
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      
      li {
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }

  .product-display {
    position: relative;
    min-height: 400px; // Adjust based on your content
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 600px; // Fixed height
      object-fit: cover; // This ensures the image covers the area without distortion
      border-radius: 8px;
      margin-bottom: 20px;
    }

    .product-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 10px;
      }

      p {
        flex-grow: 1;
        margin-bottom: 20px;
      }

   
    }
  }

  .application-home {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1rem;

    .sector-- {
      cursor: pointer;
      transition: transform 0.3s ease, background-color 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        background-color: rgba(255, 255, 255, 0.2);
      }

      span {
        font-size: 2rem;
        margin-bottom: 0.5rem;
        display: block;
      }

      p {
        font-size: 0.9rem;
        margin: 0;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      .grec-made-in-italy {
        .product-selector {
          li {
            &:hover {
              transform: none;
            }
          }
        }
    
        .application-home {
          .sector-- {
            &:hover {
              transform: none;
            }
        
          }  }  }  }
  }
}

.view-details-button{
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  padding: 15px;
  margin: 5px;
  border-radius: 15px;
  // color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg{
    font-size: 2rem;
  }
}

.Grec---container{
  height: 400px;
  margin: 10px;

  background-color: rgba(0, 0, 0, 0.22);
}
.application-sml{
  display: flex;
  flex-wrap: wrap;
  .sector--{
    font-size: 12px;
 
  }
}

.Grec--flex-img{
  display: flex;
  justify-content: space-between;
  img{
    border-radius: 7.5px;
  }
}

.Grec--flex-container{
  display: flex;
  padding: 0 5px;
  .Grec-subtitle-container{

    border-radius: 7.5px;
background-color: rgba(0, 0, 0, 0.22);
width: 50%;
padding: 50px 15px;
margin: 2.5px;
  }
}
.Grec--card--groups{
display: flex;
a{
  text-decoration: none;
}
margin: 0 5px;
  .Grec--card{
    margin: 2.5px;
    cursor: pointer;
width: 400px;
height: 300px;
padding: 15px;
border-radius: 7.5px;
background-color: rgba(0, 0, 0, 0.22);
color: white;
position:relative;
&:active{
  scale: 0.95;
}
&:hover{
  
  background-color: rgba(0, 0, 0, 0.301);
  .card-nav{
    transform: translate(7.5px, 0px);
    transition: 0.8s ease-in-out;
    }
}
.card-nav{
position: absolute;
bottom: 15px;
}
h2{
     
  font-family: "Shne Breit";
}
  }
}


.Grec--title-catory-container{

  p {
    // font-size: 3rem;
   
   
    font-family: "Shne Breit";
    color: white;
    a {
      color: white;
    }
  
  }

}

.Grec-subtitle-container{
  // max-width: 1000px;
  background: linear-gradient(135deg, rgba(12, 12, 12, 0.2549019608) 0%, rgba(0, 0, 0, 0.7725490196) 100%);
  padding: 50px;
  border-radius: 115px;
  h2{
    font-size: 2.2rem;
    line-height: 0;
    font-family: "Shne Breit";
  }

  color: white;
    margin: 15px 15px ;
    margin-bottom: 15px!important;
    h1 {
      font-family: "Shne Breit";
      line-height: 0;
      font-size: 3rem;
    }
  
    p {
      font-size: 1.5rem;
   
      margin: 0 auto;
      font-family: "Shne Breit";
    }
  
    margin-bottom: 35px;
  
    a {
      color: white;
    }
    button {
      padding: 15px 20px;
  margin-right: 5px;
      border-radius: 7.5px;
      border: none;
      cursor: pointer;
      font-size: 16px;
    
      font-family: "Shne Breit";
      font-weight: bold;
    }
  
}

.Home--cards{
  display: flex;
  justify-content: space-between;
  margin: 5px;
// div{
  
//   background-color: black;
//   width: calc(25% - 5px);
//   margin: 2.5px;
//   height: 400px;
//   border-radius: 15px;
 
// }
}


.video-container {
  position: relative;
  width: calc(99% + 5px);
  height: 78vh;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 15px;
  margin-top: 23px;
  background-color: #000000;
}
@media (max-width: 991px) {
  .video-container {
    position: relative;
    width: unset;
    height: 78vh;
    border-radius: 0;
    overflow: hidden;
    border-radius: 15px;
        margin: 28px 7.5px 16px 7.5px;
    
  }
  .Grec-subtitle-container{
    border-radius: 15px;
    margin: 16px 7.5px;
    padding: 23px;

  }
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
}


.video-social{
  a{
    color: white;
    cursor: pointer;
  }
  position: absolute;
  bottom: 15px;
  left: 5px;
  padding: 15px;
  z-index: 10;
  color: white;
}
.video-button {
  position: absolute;
  bottom: 40%;
  left: 5px;
  padding: 15px;
  z-index: 10;

  h1{
    color: white;
    font-family: "Shne Breit";
    margin-bottom: 9px;
    max-width: 481px;
    span{
      opacity: 0.3;
    }
  }
  button {
    padding: 15px 20px;
    margin-top: 12px;
margin-right: 5px;
    border-radius: 115px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border: 2px solid white ;
    font-family: "Shne Breit";
    font-weight: bold;
  }
  .button-variant-2{
    background-color: transparent;
    border: 2px solid white;
    color: white;
  }

}


// .documents-container{
//   .document-item{
//     background-color: white;
//   }
// }

.document-list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .documents-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .document-item {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .document-item:active {
    scale: 0.95
  }

  .document-item a {
    font-family: "Shne Breit";
    text-decoration: none;
    color: black;
    font-weight: bold;
  }

  .document-item a:hover {
    text-decoration: underline;
  }
}



// Applicazioni


.sector-list {

  margin: -19px auto;
  padding: 20px;
}

.sector-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  background: linear-gradient(144deg, #0c0c0c, rgba(29, 29, 29, .122));
  padding: 2rem;
  border-radius: 15px;
  margin: 0 -14px;
}

.sector-detail-info {}


.icon-container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.home-application{
  .sector-item {
 width: 436px;
  height: 400px;

  }

}

.sector-item {
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: white;
  position: relative;
  // width: 400px;
  // height: 300px;
  // height: 200px;
  // width: 223px;
  //   height: 400px;
  width: 436px;
    height: 250px;
  overflow: hidden;
  border-radius: 7.5px 7.5px;

  &:hover {
  scale: 0.98;
  // transition: 0.2s ease-in-out;
  }
  &:active {
    scale: 0.95;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  p {
    position: absolute;
    bottom: 0;
    left: 15px;
    z-index: 2;
    font-size: 1.2rem;
    font-family: "Shne Breit";
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  //   z-index: 1;
  // }

}


.sector-detail {
  color: white;
  margin: 15px 15px 25px;
  h2{
    font-family: "Shne Breit";
    font-size: 2rem;
  }
  
}


// .p-sector-detail:hover {
//   border-radius: 7.5px 7.5px;
// background-color: rgba(128, 128, 128, 0.048);
// }

.p-sector-detail {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  padding: 4px;

  a {
    color: white;
    // font-family: "Shne Breit";
    // background-color: black;
    // padding: 5px
    background-color: rgb(255 255 255);
    // background-color: rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    // color: white;
    color: black;
    font-weight: 500;
    padding: 7.5px;
    text-decoration: none;
    border-radius: 7.5px 7.5px;
    margin-left: 5px;
  }

}

.sector-detail-info {
  // margin: 2.5% 0;
}




.modal-application {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 68%);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
 
}

.modal-content-application {
  border-radius: 7.5px;
  background-color: #2121216b;
  backdrop-filter: blur(52px);
  width: 1000px;
  position: relative;
  animation: bounceIn 0.6s ease-out;


}

.image-container {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.modal-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  user-select: none;
}

.image-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom,  rgba(0, 0, 0, 0), #212121);
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
}




@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  70% {
    transform: scale(1.01);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}





.close-button-application{
  position: absolute;
 
  display: flex;
  align-items: center;

  cursor: pointer;
  z-index: 1500;
 
  
  top: 10px;
    right: 10px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 24px;
    &:active{
      scale: 0.95;
    }

   
}


// Gallery

.gallery-modal-control{

.Grec--machine-icon{
  background-color: white;
  padding: 7.5px;
  border-radius: 7.5px;
 
}

.prev{
  top: calc(50% - 30px);
  left:15px;
  position: absolute;
  :active{
    scale: 0.90
  }
    }
    .next{
      top: calc(50% - 30px);
     right: 15px;
     position: absolute;
     :active{
      scale: 0.90
    }
    }


  .Grec--machine-control{
    position: absolute;
height: 22px;
width: 22px;
  }
  

}

.gallery-container {
  padding: 20px;
  
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 15px;
  border-radius: 15px;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.image-item {
  cursor: pointer;
  overflow: hidden;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 7.5px;
  
}

.image-item img:hover {
  // transform: scale(1.1);
  scale: 0.99;
}

.modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  animation: fadeIn 0.3s ease-out;
}

.modal-content {
position: relative;
  max-width: 90%;
  max-height: 90%;
  animation: bounceIn 0.6s ease-out;
  border-radius: 7.5px;
  
 
}

.close-button {
  position: absolute;
  top: 20px;
  right: 15px;
  color: black;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1500;
}


// Discalimer

.discaimer-content{
  max-width: 1000px;
  margin: 0 auto;
  display: flex;

  button{
    width: 33%;
    padding: 15px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    &:active{
      scale: 0.95;
    }
  }

}

.discaimer-content-body{
  max-width: 1000px;
  margin: 0 auto;
  color: white;
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 15px;
  border-radius: 7.5px;
 
 
  h2{
    
    font-family: "Shne Breit";
  }
}


.button-group {

  gap: 10px;
  padding: 5px;
  // transition: all 0.3s ease-in-out;
  
}

.button-group.fixed {
  position: fixed;
  top: 69px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 114;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  
  // transition: all 0.6s ease-in-out;

  .discaimer-content{
    max-width: 1000px;
 
    display: flex;
    button{
     
      padding: 7.5px;
    
     
    }
 
  }
  
}

// Contact Form

.Grec--contact-container{
  margin: 0 auto;

}

.Grec--contact-form{
  margin: 0 auto;
  max-width:580px;
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;

  
  .input-full{
    border-radius: 5px;
    border: none;
    width: calc(99.5% - 30px);
    padding: 15px;
    margin: 2.5px;
    font-family: inter;
    font-weight: 600;
    font-size: 1.01rem;
  }

  & > div:nth-child(3){
    display: none;
  }

  & > div:nth-child(4){
    textarea{
      min-height: 150px;
    }
  }


  .Grec--contact-head{
    display: flex;

   
  }
  .Grec--contact-body{
  textarea{
    width: calc(99.5% - 30px);
    max-width: calc(99.5% - 30px);
    padding: 15px;
    margin: 2.5px;
    border-radius: 5px;
    border: none;
    font-size: 1.01rem;
    font-family: inter;
    font-weight: 600;
  }
  textarea{
    min-width: calc(99.5% - 30px);
    min-height: 170px;
  }
  label{
    color: white;
    a{
      color: white;
    }
  }
  }

  button{
    width: 100%;
    border-radius: 100px;
    padding: 15px;
    border: none;
    font-size: 1.01rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: linear-gradient(9deg, #00000042, #434343);
// color: rgb(0, 0, 0);
background-color: #e44949;
font-weight: 600;
font-family: "Shne Breit";
  }
}

.Maps{
  margin: 0 auto;
  max-width: 1050px;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 15px;
  border-radius: 15px;
  iframe{
    border-radius: 7.5px;
  }
}



// .contact-us--page {
//   display: flex;
// }


// Download

// .page--container {
//   position: relative;
//   overflow-x: hidden;
// }

.Grec-title-container {
  margin-bottom: 2rem;

  .grec-pdf-filter {
    padding: 0.5rem;
    margin-top: 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 115px;
    font-family: "Shne Breit";
    font-weight: 600;
    max-width: 600px;
    padding: 15px;
    width: calc(90% - 50px);
    max-width: 600px;
    border: 1px solid #ccc;
  }
}

@media (max-width: 1100px) and (min-width: 599px) {
  .Grec-title-container {
    margin: 0 3px 20px!important;
    h1 {
      margin: 33px 0 16px 0;
      padding: 0 10px;
      line-height: 1.2!important;
      font-size: 2.5rem!important;
    }
  }
}

@media (max-width: 400px) {
  .Grec-title-container {
    margin: 0 3px 35px!important;
    h1 {
      padding: 0 10px;
      line-height: 1.2!important;
      font-size: 1.5rem!important;
    }
    p{
      hyphens: auto;
      line-height: 1.4;
    }
  }
}

// prodotti

.Grec--card-container {
  margin: 0 auto;
 
  justify-content: flex-start;
  /* max-width: 1800px; */
  flex-wrap: wrap;
  padding: 2.5% 4%;
  display: flex;
  justify-content: left;
  /* max-width: 1800px; */
  flex-wrap: wrap;
  background: linear-gradient(144deg, #0c0c0c4f 0%, rgba(29, 29, 29, 0.1215686275) 100%);
  margin: 0 auto;
  // border-radius: 12px;
  .Grec--machine-card {
   
    .card-img-container {
      // width: 100%!important;
      // height: 300px!important;
    
  
    }
  }
}

.Grec--card-container.giranti{

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    .Grec--machine-card {
      aspect-ratio: 330/432;
      height: unset;
    }

    .Grec--machine-card .card-img-container{
      width: 100%;
      aspect-ratio: 1;
      height: unset;
    }
}





@media (max-width: 767px) {
  .Grec--card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(252px, 1fr));
  }

  .Grec--machine-card {
    aspect-ratio: 330/432;
    height: unset;
  }

  .Grec--machine-card .card-img-container{
    width: 100%;
    aspect-ratio: 1;
    height: unset;
  }
}

@media (max-width: 600px) {
  .Grec--machine-card {
    padding: 15px;

    p{
      height: 32px;
      line-height: 1!important;
    }
  }
}


@media (max-width: 400px) {
  .Grec--card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}
// fine prodotti

.grec-download-section {
  padding: 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  transition: all 0.3s ease;
  background: linear-gradient(144deg, #0c0c0c4f 0%, rgba(29, 29, 29, 0.1215686275) 100%);
  margin: 7.5px 7.5px -1px;
  border-radius: 15px;
  .grec-document-list-title {
    background-color: rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    color: white;
    padding-left: 15px;
    max-width: 300px;
    display: flex;
    align-items: center;
    gap: 5px;

    h2 {
      color: white;
      font-size: 1.2rem;
    }
  }

  &.grec-with-pdf {
    grid-template-columns: 1fr 1fr 2fr;
  }

  .grec-document-lists-container {
    display: contents;
  }

  .grec-document-list {
   
    h2 {
      margin-bottom: 1rem;
    }

    .grec-documents-container {
      display: flex;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.22);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      color: white;
      padding: 15px;
    }

    .grec-document-item {
      margin-bottom: 0.5rem;
      font-family: inter;
      font-weight: 600;
      background-color: white;
      border-radius: 8px;
      padding: 15px;
      text-decoration: none;
      color: black;
      transition: all 0.3s ease;

      &:hover {
        background-color: #f5f5f5;
      }

      &:active {
        transform: scale(0.96);
      }

      &.active {
        background-color: #ffffff;
        color: rgb(0, 0, 0);
      }

      &.pdf-open:not(.active) {
        background-color: rgba(0, 0, 0, 0.055);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        color: white;
      }
    }
  }

  .grec-pdf-viewer {
    position: relative;
    display: flex;
    flex-direction: column;

    iframe {
      flex-grow: 1;
      width: 100%;
      min-height: 1000px;
      border: none;
      border-radius: 15px;
    }

    .grec-pdf-controls {
      display: flex;
      justify-content: right;
      padding: 10px;
      background-color: transparent;
      gap: 15px;

      button {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 15px;
        background-color: white;
        color: black;
        font-family: "Shne Breit";
        font-weight: 600;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #dbdbdb;
        }

        &:active {
          transform: scale(0.96);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .grec-download-section {
    grid-template-columns: 1fr;

    &.grec-with-pdf {
      grid-template-columns: 1fr;
    }

    .grec-pdf-viewer {
      display: none;
    }
  }
}

.video-section{
  margin-top: 90px;
}

.new-video-section{
  display: flex;
 align-items: center;
 
 .video-wrapper{
  margin-top: 60px;
 }
  .new-video{
    width: 90%;

    margin: 15px;
    border-radius: 8px;
  }
h2{
  font-family: "Shne Breit";
  color: white;
}
  .application-home{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    .sector--{
width: 280px;
text-align: center;
padding: 15px;
    border-radius: 7.5px;
    background-color: rgba(0, 0, 0, 0.22);
    color: white;
    }
  }
}

// product list

.giranti-table-container {
  margin: 2rem 5%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 1rem;

  h2 {
    color: white;
    margin-bottom: 1rem;
  }
}

.giranti-table-wrapper {
  overflow-x: auto;
}

.giranti-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  color: white;
  font-size: 0.9rem;

  th, td {
    padding: 0.8rem 1rem;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    vertical-align: middle;
  }

  th {
    background-color: rgba(0, 0, 0, 0.2);
    font-weight: bold;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  tbody {
    tr {
      &:last-child td {
        border-bottom: none;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }

    td {
      &:nth-child(n+2):nth-child(-n+8) {
        text-align: center;
      }
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .giranti-table {
    font-size: 0.8rem;

    th, td {
      padding: 0.6rem 0.8rem;
    }
  }
}

// For very small screens, consider a different layout
@media (max-width: 480px) {
  .giranti-table {
    font-size: 0.7rem;

    th, td {
      padding: 0.4rem 0.6rem;
    }
  }
}

// Lavora con Noi

.work-con-noi2 {
  display: flex;
  justify-content: center;
  gap: 15px;
  /* background-color: rgba(0, 0, 0, 0.22); */

  /* border-radius: 15px; */

}
.work-con-noi{
  display: flex;
  justify-content: center;
  gap: 15px;
  background-color: rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    /* border-radius: 15px; */
    padding: 30px;
  .Grec--contact-form {
    margin: 0 ;
    width: 100%;
    textarea{
min-height: 200px;
    }
  }
  .lavori-list{
    margin: 0 ;
    
  }
  .lavori-list .lavoro-item .lavoro-header{
    padding: 20px;

   
  }
  .lavori-list .lavoro-item{
    border-radius: 15px;
  }
}

.lavoro-header{
  h2{
    // font-family: "Shne Breit";
    font-weight: bold;
  }
}

.p-form-header{
  color: white;
  margin-top: -10px;
  strong{
    font-size: 1.3rem;
    line-height: 2;
   
  }
  margin-bottom: 15px;
}

#cookiescript_header{
     font-family: "Shne Breit";
}

#cookiescript_injected {  

  width: 387px!important;

}

.buttonDetailsScheda{

  max-width: 560px;

  padding: 15px 20px;

  border-radius: 7.5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-family: "Shne Breit";
  font-weight: bold;

a{
  text-decoration: none;
  color: black;
}
}


.image-caption {
  position: absolute;
  // bottom: 20px;
  margin-top: 30%!important;
  border-radius: 8px;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.459);
  backdrop-filter: blur(20px);
  color: white;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
}

.ifram-dynamic-info-bar{
position: absolute;
 right: 15px;
  top: 15px;
  color: white;
  display: flex;

gap: 5px;
  span{
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 15px 12px 5px;
  border-radius: 15px;
  p{
    opacity: 0.3;
  }
  &:hover{
    p{
      opacity: 1;
    }
  }
  }
}

@media (max-width: 767px) {
  .ifram-dynamic-info-bar{
    display: none;
  }
}

.zoom-info {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 2px 5px;
  border-radius: 3px;






}



.product-catalog {
  background: linear-gradient(60deg, #0c0c0c 0%, #1d1d1d1f 100%);
  color: white;
  padding: 2rem;
  margin: 7.5px;
  border-radius: 15px;
a {
  text-decoration: none;
  color: white;
}
  .agitator-section {
    margin-bottom: 2rem;

    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h2 {
        font-size: 1.5rem;
        font-weight: bold;
        font-family: "Shne Breit", sans-serif;
      }

      .show-all {
        font-size: 0.9rem;
        cursor: pointer;
      }
    }

    .agitator-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 1rem;
    }
    .lateral-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      grid-gap: 1rem;
    }
  }


  .bg-icon{
position: absolute;
top: 0;
right : 0 ;

opacity: 0.1;
  }
  .img--card{
    z-index: 2!important;
    
  }

  .img-av{
    max-height: 454px!important;
    background: linear-gradient(135deg, rgba(12, 12, 12, 0.2549019608) 0%, rgba(0, 0, 0, 0.7725490196) 100%);
    margin-right: 15px;
    border-radius: 8px;
  }
  .product-card {
    background: linear-gradient(135deg, #0c0c0c41 0%, #000000c5 100%);
    padding: 1rem;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
position: relative;
    &.vertical-agitator {
      img {
      
        margin-bottom: -101px;
        
      }
    }

    &.lateral-agitator {
      // min-width: 411px;
      img {
        height: auto;
        margin-bottom: -78px;
      }
    }

    .card-arrow {
      position: absolute;
      right: 10px;
      bottom: 5%;
      transform: translateY(-50%);
      opacity: 0.2;
      transition: opacity 0.3s ease;
    }
    .ddd-card{
      position: absolute;
      right: 10px;
     top: 25px;
      transform: translateY(-50%);
      opacity: 0.2;
      transition: opacity 0.3s ease;
    }

    img {
      width: 100%;
      height: auto;
      margin-bottom: -83px;
    }

    p {
      text-align: left;
      font-size: 1.1rem;
      font-weight: bold;
      font-family: "Shne Breit", sans-serif;
      span{
        font-family: "Inter", sans-serif;
        font-size: 0.8rem;
        opacity: 0.4;
      }
    }

  

    &:hover {
      background: linear-gradient(-180deg, #0c0c0c80 0%, #24242477 190%);

      .card-arrow {
        opacity: 1;
      }
      .ddd-card{
        opacity: 1;
      }
    }
  }

.img--cardA{
  max-height: 460px;
 object-fit: contain;
}
.agitator-grids{
  display: flex;
  gap: 15px;
  @media (max-width: 768px) {
flex-direction: column;
  }
}

  .view-all-button {
    background: linear-gradient(135deg, #0c0c0c 0%, #1d1d1d8f 100%);
    color: white;
    border: none;
    padding: 2rem 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: bold;
    font-family: "Shne Breit", sans-serif;
    font-size: 1.3rem;
    .arrow-icon {
      margin-left: 0.5rem;
    }
  }
}

// Media queries for responsiveness
@media screen and (max-width: 1200px) {
  .product-catalog {
    .agitator-section {
      .agitator-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        grid-gap: 1rem;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .product-catalog {
    .agitator-section {
      .agitator-grid {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .product-card {
      &.lateral-agitator {
        min-width: unset;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .product-catalog {
    .view-all-button {
      border-radius: 15px!important;
      padding: 2rem 3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-weight: bold;
      font-family: "Shne Breit", sans-serif;
      font-size: 1.3rem;
      .arrow-icon {
        margin-left: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .product-catalog {
    padding: 1rem;

    .agitator-section {
      .section-header {
        flex-direction: column;
        align-items: flex-start;

        h2 {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
        }
      }

      .agitator-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 1rem;
      }
    }

    .product-card {
      img {
        margin-bottom: -40px;
      }

      &.lateral-agitator img {
        margin-bottom: -40px;
      }

      p {
        font-size: 1rem;
      }
    }

    .view-all-button {
      padding: 1.5rem 2rem;
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 400px) {
  .product-catalog {
    padding: 1rem;

    .agitator-section {

      .agitator-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-gap: 1rem;
      }
    }

    .view-all-button {
      font-size: 1rem;
      padding: 1.8rem;
      .arrow-icon {
        margin-left: 0.5rem;
      }
    }
  }
}

.fiere-popup {
  width: auto !important;
}

.modal-combined {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content-combined {
  border-radius: 7.5px;
  background: linear-gradient(144deg, #0c0c0c, rgba(29, 29, 29, 0.122));
  backdrop-filter: blur(52px);
  width: auto;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  padding: 20px;
}

.close-button-combined {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  z-index: 1000;
}

.section-title-combined {
  color: white;
  margin-bottom: 15px;
  font-size: 2rem;
  font-family: "Shne Breit", sans-serif;
  text-transform: uppercase;
}

.fiere-section-combined, .popup-section-combined {
  margin-bottom: 30px;
}

.fiera-item-combined, .popup-item-combined {
  background: linear-gradient(60deg, #0c0c0c 0%, rgba(29, 29, 29, 0.1215686275) 100%);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.fiera-info-combined, .popup-content-combined {
  flex: 1;
  color: white;
}

.fiera-image-combined, .popup-image-combined {
  width: auto;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
  margin-left: 15px;
}

.popup-image-combined {
  width: auto;
  height: 200px;
}

.fiera-item-combined h3, .popup-item-combined h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-family: "Shne Breit", sans-serif;
  max-width: 400px;
}

.fiera-item-combined span, .fiera-item-combined p {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.popup-content-combined {
  font-size: 14px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .modal-content-combined {
    width: 95%;
    padding: 15px;
  }

  .section-title-combined {
    font-size: 1.5rem;
  }

  .fiera-item-combined, .popup-item-combined {
    flex-direction: column;
    align-items: flex-start;
  }

  .fiera-image-combined, .popup-image-combined {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-top: 10px;
  }

  .popup-image-combined {
    width: 100%;
    height: auto;
    max-height: 150px;
  }

  .fiera-item-combined h3, .popup-item-combined h3 {
    font-size: 16px;
  }

  .fiera-item-combined span, .fiera-item-combined p, .popup-content-combined {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  .modal-content-combined {
    padding: 10px;
  }

  .section-title-combined {
    font-size: 1.2rem;
  }

  .close-button-combined {
    font-size: 20px;
    padding: 3px;
  }

  .fiera-item-combined, .popup-item-combined {
    padding: 10px;
  }
}


.reminder-icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  backdrop-filter: blur(1000px);
  color: #000000;
  background: #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 100021;
}
.certifications-link {
  display: flex;
  flex-direction: column; /* Disposizione verticale */
  align-items: center; /* Allinea al centro */
  text-decoration: none; /* Rimuove la sottolineatura */
}

.certifications-text {
  font-size: small; /* Font piccolo */
  color: white; /* Scritta bianca */
  text-align: center; /* Centra il testo */
}

.reminder-icon:hover {
backdrop-filter: blur(10px);
}
.full-width .fiere-item {
  margin-bottom: 10px;
}
.fiere-section.full-width {
  display: block !important;
  margin-top: 100px;
}
.fiere-section{

  background: linear-gradient(144deg, #0c0c0c 0%, rgba(29, 29, 29, 0.1215686275) 100%);
  margin: 7.5px;
  border-radius: 15px;
  width:100%;
  color: white;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 2rem;
}
@media screen and (min-width: 911px) and (max-width: 1250px){
  .fiere-section{
    .fiere-item{
      display: block;
      .fiere-info{
        margin: 0 0 12px 0
      }
    }
  }
}
@media screen and (max-width: 700px){
  .fiere-section{
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    .fiere-item{
      display: block;
      .fiere-info{
        margin: 0 0 12px 0
      }
    }
  }
}
@media screen and (max-width: 565px){
  .fiere-section{
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    .fiere-item{
      img{
        width: 100%;
        height: auto;
        // max-width: 150px;
      }

    }
  }
}
@media (max-width: 400px) {
  .fiere-section{
    .fiere-item{
      .fiere-info{
        h2{
            font-size: 1.2em;
            hyphens: auto;
        }
      }
    }
  }
}

.downloads-section{
  background: linear-gradient(144deg, #0c0c0c 0%, rgba(29, 29, 29, 0.1215686275) 100%);
  margin: 7.5px;
  border-radius: 15px;
  color: white;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .downloads-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      font-size: 2rem;
      font-weight: bold;
      font-family: "Shne Breit", sans-serif;
    }

    .show-all {
      font-size: 0.9rem;
      cursor: pointer;
    }
  }

  .downloads-content {
    display: flex;
    gap: 1rem;

    a{
      text-decoration: none;
      color: white;
    }
    a:visited{
      color: white;
    }
  }


  .download-item {
    background-color: rgba(0, 0, 0, 0.062);
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-width: 300px;
    gap: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    transition: opacity 0.3s ease;
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
      .chevron-icon {
  
        opacity: 1;
      }
    }

    .flag-icon {
      width: 80px;
      height: 80px;
    }

    .download-text {
      flex-grow: 1;
      p {
        margin: 0;
        font-family: "Shne Breit", sans-serif;
        font-weight: bold;
        font-size: 1.2rem;
      }
    }

    .chevron-icon {
      color: white;
      opacity: 0.2;
    }
  }
.vito{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
  .folder-icon {
    // position: absolute;
    right: 2rem;
    bottom: -2rem;
    width: 615px;
    height: auto;
  }
}

.down-group{
  width: 100%;
    background: linear-gradient(60deg, #0c0c0c 0%, rgba(29, 29, 29, 0.1215686275) 100%);
    padding: 42px;
    margin-right: -53px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

@media (max-width: 1400px) {
  .downloads-section{
    .folder-icon {
      // position: absolute;
      right: 2rem;
      bottom: -2rem;
      width: 400px;
      height: auto;
    }
  }
  .down-group{
    width: 100%;
      background: linear-gradient(90deg, rgba(12, 12, 12, 0.763) 0%, rgba(29, 29, 29, 0) 94%);
      padding: 42px 56px 42px 42px;
      margin-right: -53px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
  }
  
}

@media (max-width: 1200px) {
  .downloads-section{
    .downloads-content {
      display: flex;
      gap: 0.9rem;
    }

    .download-item {
      min-width: 210px;
      padding: 0.9rem;
    }

  }
  .down-group{
    width: 100%;
      background: linear-gradient(90deg, rgba(12, 12, 12, 0.763) 0%, rgba(29, 29, 29, 0) 94%);
      padding: 42px 56px 42px 42px;
      margin-right: -53px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
  }
  
}

@media (max-width: 991px) {
  .downloads-section {
    .downloads-content {
      flex-direction: column;
    }
    h2{
      margin-top: 0;
    }
    .downloads-header{
      display: block
    }

    .folder-icon {
      // width: 100px;
      right: 1rem;
      bottom: -1rem;
    }
  }
}
@media (max-width: 767px) {
  .downloads-section {
    display: block;
    
    & > img:nth-child(2){
      display: block;
      margin: 0 auto;
    }
    .down-group{
      padding: 32px;
      width: calc(100% - (14px + 3rem));
      margin: 0;
    }

    .downloads-content {
      flex-direction: row;

      .download-item{
        width: calc(50% - 0.9rem);
      }

    }
    h2{
      margin-top: 0;
    }
    .downloads-header{
      display: block
    }

    .folder-icon {
      // width: 100px;
      right: 1rem;
      bottom: -1rem;
    }
  }
}
@media (max-width: 600px) {
  .downloads-section {


    .downloads-content {
      flex-direction: column;

      .download-item{
        width: 100%;
      }
    }

    & > img:nth-child(2){
      max-width: 400px;
      width: 100%;
      display: block;
      margin: 0 auto;
      margin-bottom: -24px !important;
    }
  }
}

@media (max-width: 400px) {
  .downloads-section {

    .downloads-header{
      h2{
       font-size: 1.5rem;
      }
    }
  }
}



.fiere-item{  
display: flex;

margin-right: 20px;
background: linear-gradient(60deg, #0c0c0c 0%, rgba(29, 29, 29, 0.1215686275) 100%);
padding: 42px;
border-radius: 7.5px;
justify-content: space-between;

align-items: center;
font-family: "Shne Breit", sans-serif;

img{
  height: 100px;
}
span{
  margin-top:-10px;

}

div {
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
}
p{
  margin-top:20px;
}
}

.fiere-info{
  p{
    font-size: 0.8rem;
 font-family: inter , san-serif;
 font-weight: bold;
  }
}
.map-wrap {
  height: 700px;
  width: 98%;
  border-radius: 15px;
  margin: -30px auto;
  margin-bottom: 1%;
  position: relative;
  overflow: hidden;

  .maplibregl-map {
    height: 100%;
    width: 100%;
    border-radius: 15px;
  }
.vitoo{
  position: absolute;
 bottom: 0px;
  right: 00px;
  background-color: rgb(0 0 0 / 88%);
  backdrop-filter: blur(100px);
  width: 20%;
 
  z-index: 2;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 35px;
  button {
    background-color: #E44949;
    color: white;
    border: none;
    padding:15px 25px;
    font-family: "Shne Breit", sans-serif;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 115px;
 
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(#E44949, 10%);
    }
  }
}
  .info-box {
    position: absolute;
    top: 0px;
    left: 00px;
    background-color: rgb(0 0 0 / 88%);
    backdrop-filter: blur(30px);
    padding: 15px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
    max-width: 250px;
    height: 670px;
    z-index:2;

    h3 {
      margin: 0 0 10px 0;
      font-family: "Shne Breit", sans-serif;
    }

    p {
      margin: 0 0 15px 0;
    }

   
  }
}

@media (max-width: 991px) {
  .map-wrap .vitoo{
    padding: 28px;
    width: fit-content;
    button{
      padding: 15px 20px;
      width: fit-content;
    }
  }
}

@media (max-width: 767px) {

  .map-wrap{
    margin-bottom: 14px;

  }
  .map-wrap .vitoo{
    top: 0;
    left: 0;
    right: unset;
    bottom: unset;
    border-radius: 14px 0 0px 0px;
    background: linear-gradient(90deg, rgba(12, 12, 12, 0.8), rgba(29, 29, 29, 0));
    backdrop-filter: unset;
    padding: 24px 40px 24px 16px;
    width: fit-content;
    button{
      padding: 15px 20px 12px 20px;
      width: fit-content;
    }
  }
  .map-wrap .info-box{
    bottom: 0;
    border-radius: 0 0 16px 13px;
    top: unset;
    width: 96%;
    max-width: unset;
    height: auto;
    padding: 18px 15px;
    background: linear-gradient(120deg, rgba(12, 12, 12, 1) 30%, rgba(29, 29, 29, 0.7) 100%);

    img{
      width: 160px;
      height: auto;
    }
    h3{
      margin: 0;
    }
    p{
      margin: 0;
    }
  }
}

@media (max-width: 400px) {
  .map-wrap{
    height: 80dvh;
    margin: 12px auto;
    width: calc(98% - 8px);
  }
}


// Stili per il punto GREC sulla mappa
.maplibregl-canvas {
  .grec-point {
    circle-radius: 20px;
    circle-color: #E44949;
    circle-stroke-width: 3px;
    circle-stroke-color: #0000003a;
  }

  .grec-label {
    text-size: 25px;
    text-color: #00000000;
    text-halo-color: #ffffff;
    text-halo-width: 2px;
  }
}

.icon-category{
  width: 100%!important;
  max-width: 400px;
  margin: 0 auto;
}

.category-image-img{
  position: fixed;
  z-index: -1;
  top: 0;
}

.category-image-link{
  background-color: #ffffffd4;
  border-radius: 8px;
  backdrop-filter: blur(104px);
  border-radius: 8px;
  transition: all 0.3 ease-in-out;
}

.category-image-link:hover{
  background-color: #ffffffc0;
  border-radius: 8px;
  backdrop-filter: blur(104px);
  border-radius: 8px;

}

.category-image{
 width: 100%;
 height: auto;

}

.category-images-container{
  display: flex;
  justify-content: space-between;
  background: linear-gradient(144deg, #0c0c0c 0%, rgba(29, 29, 29, 0.1215686275) 100%);
  margin: 7.5px 7.5px -1px;
  border-radius: 12px;
  padding: 2rem;
  gap: 1.2em
}



.fireWall{
  color: white;
  display: flex;
  align-items: center;
  gap: 15px;
  // justify-content: center;
background: linear-gradient(144deg, #0c0c0c 0%, rgba(29, 29, 29, 0.1215686275) 100%);
margin: 1px 20px 15px;
border-radius: 122px;
padding: 35px 40px;
&:hover{
  img{
  
    opacity:0.5;
  }
}
  img{
    height: 40px;
    width: auto;
    opacity: 0.3;
  }
  p{
    max-width: 62%;
  }
}

#cookiescript_badge{
  background: #000000!important;

}
#cookiescript_badgetext{
  font-family: "Shne Breit"!important;
}
#cookiescript_injected {
  background-color: #101010cc!important;
  backdrop-filter: blur(82px)!important;
  border-radius: 10px!important;
}

#cookiescript_injected .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, #cookiescript_injected .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, #cookiescript_injected_fsd .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, #cookiescript_injected_fsd .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
  border-color: #ffffff!important;
  background-color: #f7f7f7!important;
}

#cookiescript_accept, #cookiescript_reject, #cookiescript_save {
  border-radius: 9px!important;
  font-family: "Shne Breit"!important;
}
#cookiescript_header{
  font-family: "Shne Breit"!important;
  text-transform: uppercase!important;
}

#cookiescript_checkbox_text{
  font-family: "Shne Breit"!important;
}

#cookiescript_description {
 
  font-family: Poppins!important;}


.Grec--contact-container{


    
    .map-wrap {
   
      width: 99%!important;
      height: 700px!important;
      margin: 30px auto!important;
    
  }


  .info-box {
  
  max-width: 500px!important;}

  .Grec--contact-form{
    backdrop-filter: none!important;
    background-color: transparent!important;
    h2{
      font-size: 1.7rem;
      font-weight: bold;
      font-family: "Shne Breit", sans-serif;
      margin-top: -8px!important;
    }
  }
}
@media (max-width: 991px) {
  .Grec--contact-container{
    .map-wrap{
      overflow:auto;
      height: auto!important;
      display: flex;
      flex-direction: column-reverse;
      gap: 1.2rem;

      .maplibregl-map{
        width: 100%;
        height: 400px;
        margin-bottom: -36px;
      }

      .vitoo{
        padding: 20px;

        button{
          padding: 12px 18px;
          font-size: 0.9rem;
        }
      }
      
      .info-box {
        position: static;
        width: calc(100% - 2rem);
        max-width: unset !important;
        border-radius: 16px !important;
      }
    }
  }
}
@media (max-width: 500px) {
  .Grec--contact-form{
    padding: 0;
  }
}




  .gradient-page{
    background: linear-gradient(144deg, #0c0c0c63 0%, rgba(29, 29, 29, 0.01) 100%);
margin: 7.5px 1%;
border-radius: 12px;
padding: 42px;
  }

  .mt-fix{
    margin-top:5%!important;
  }

  .footer-info-legal{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  .nonclic{
    user-select: none;
  }

  @media (max-width: 991px) {
    .category-images-container{
      flex-wrap:wrap;
      justify-content: center;
      gap: 1.6rem;
      padding: 32px;
  
      .category-image-link{
        width: calc(33% - 1.6rem);
      }
  
    }
  }

  @media (max-width: 767px) {
    .fireWall{
      margin: 2px 7.5px;
      width: unset;
      margin-bottom: 16px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;

      p{
        max-width: 90%;
      }
    }
    
  }

  @media (max-width: 600px) {
    .category-images-container{
      .category-image-link{
        width: calc(50% - 0.8rem);
      }

      a:last-child{
        width: 100%;
        text-align: center;
        
        img{
          width: calc(50% - 0.8rem);
        }
      }
    }
  }


  .absolute-title{
    position: absolute;
    color: white;
    font-family: "Shne Breit", sans-serif;
    top: 15px;
    left: 2rem;
    max-width: calc(100% - (4rem + 10px));
    font-size: 1.5rem;
    padding: 4px 2px 4px 8px;
    transition: 0.2s;
    background-color: rgb(255 255 255 / 4%);
    border-radius: 8px;
    cursor: pointer;
svg{
  opacity: 0.2;
  scale: 0.8;
}



&:hover{
  background-color: rgba(255, 255, 255, 0.007);
  svg{
    opacity: 1;
    scale: 1;
  
  }
}
  }
  @media (max-width: 500px) {
    .absolute-title{
        font-size: 1.4rem ;
    }
  }


.with-absolute-title{
position: relative;
padding-top: 110px;
}

.link-homepage{
  text-decoration: none;
  color: white;
}
.link-homepage:visited{
  color: white;
}
.cont-lang-toggle{
  position: absolute;
  bottom: 86px;
  left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  img{
    margin: 0!important;
    display: block;
  }
}

.position-relative{
  position: relative;
}
@media (min-width: 991px) {
  .cont-lang-toggle{
    display: none;
  }
}


.special-product-section{
  padding: 2.5% 4%;


  background: linear-gradient(144deg, rgba(12, 12, 12, 0.3098039216) 0%, rgba(29, 29, 29, 0.1215686275) 100%);
  margin: 0 5%;
  border-radius: 12px;
  color: white;
  font-size: 1.2rem;
  a{
    color: black;
    text-decoration: none;
  }
}

.buttonz{


  max-width: 560px;
  margin: 15px;
  padding: 15px 20px;
  margin-right: 5px;
  border-radius: 7.5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-family: "Shne Breit";
  font-weight: bold;
}

.static-mixer-image-container{
  display: flex;
  width: 100%;
  justify-content: center;
}

.description-title{
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Shne Breit", sans-serif;
  color: white;
  margin-left: 2%;
  background: linear-gradient(144deg, rgba(12, 12, 12, 0.3098039216) 0%, rgba(29, 29, 29, 0.1215686275) 100%);
  margin: 0 auto;
text-align: center;
  padding: 15px;
  margin-top: 25px;
padding-bottom: 0;

}

.p-danger{
ul{
  text-align: left;
}
}


.certifications__logo{
height: 120px!important;
}

.certifications__img-card{
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  width: 100%;
  max-width: 600px;
  aspect-ratio: 3/4;
}

@media (max-width: 768px) {
  .certifications__img-card{
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
    width: 100%;
    height: unset;
    aspect-ratio: 3/4;
  }
}

.form-division{
  display: flex;
  gap: 15px;

}


.certifications{
  align-items: center;
  display: flex;
  flex-direction: column ;
  gap: 15px;
  flex-wrap: wrap;

  .grec-document-list-title{
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 32px;

    h2{
      color: white;
    }

    .download-button{
      background: linear-gradient(80deg,rgb(65, 65, 65), rgb(85, 85, 85)  ) ;
    }
  }

  .grec-documents-container{
    display: flex;
    gap: 40px;
    width: 100%;

      div{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  }


  button{
    width: 100%;
    padding: 15px;
    border-radius: 15px;
    border: none;
    margin-top: 10px;
    cursor: pointer;
    font-family: "Shne Breit";
    font-weight: bold;
    font-size: 1rem;
    background: #2d2d2d;
    color: white;
  }
}

.Grec--footer{
  .certifications__logo{
  
  }
}



.quote-modal-form {
  margin: 0 auto;
  max-width: 580px;
  background-color: rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;
}

.quote-modal-header {
  color: white;
  margin-bottom: 20px;
}

.quote-modal-input-group {
  // margin-bottom: 15px;
}

.quote-modal-download-btn{
  font-weight: normal!important;
  margin-top: 15px ;
}

.quote-modal-input,
.quote-modal-textarea,
.quote-modal-select {
  border-radius: 5px;
  border: none;
  width: 100%;
  padding: 15px;
  margin: 2.5px 0;
  font-family: inter, sans-serif;
  font-weight: 600;
  font-size: 1.01rem;
}

.quote-modal-textarea {
  min-height: 150px;
  resize: vertical;
}

.quote-modal-file-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.quote-modal-file-input {
  flex: 1;
  margin-right: 10px;
}

.quote-modal-download-btn {
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: #e44949;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.quote-modal-select-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 5px;
}

.quote-modal-select {
  width: 48%;
}

.quote-modal-tank-info {
  margin-top: 20px;
}

.quote-modal-subheader {
  color: white;
  margin-bottom: 10px;
}

.quote-modal-submit-btn {
  width: 100%;
  border-radius: 115px!important;
  padding: 15px;
  border: none;
  font-size: 1.01rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e44949!important;
  color: white;
  font-weight: 600;
  font-family: "Shne Breit", sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
  
    background-color: #e44949;
  }
}

@media (max-width: 768px) {
  .quote-modal-form {
    padding: 20px;
  }

  .quote-modal-select-group {
    flex-direction: column;
  }

  .quote-modal-select {
    width: 100%;
    margin-bottom: 10px;
  }

  .quote-modal-file-group {
    flex-direction: column;
  }

  .quote-modal-file-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .quote-modal-download-btn {
    width: 100%;
  }
}

.form-division{
  display: flex;
  gap: 15px;

}

@media only screen and (max-width: 1136px) {


  .form-division{
flex-direction: column

  
  }



  .modal-content-combined {
  
    max-height: 90vh;
}}

.prevenito-form{
  .quote-modal-form{
    max-width: 920px !important;
  }
  .form-division{
 
  }
}

.quote-modal-input, .quote-modal-textarea, .quote-modal-select {
  width: 94%;
}


.quote-modal-select {

    width: 100%!important
 
}
.quote-modal-description{
  color: white;
}

.quote-modal-title{
  font-family: "Shne Breit", sans-serif;
  font-size: 2rem;
}


.flex-input{
  display: flex;
  gap: 5px;
}

.quote-modal-file-group{
  color: white!important;
}

.quote-modal-download-btn{
  
}


.progress-bar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  transition: height 0.1s linear;
}

.cont-btn-lang-cert{
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 40px 0;

  .btn-lang-cert{
    border-radius: 12px;
    text-decoration: none;
    background: linear-gradient(80deg,rgb(65, 65, 65), rgb(85, 85, 85)  ) ;
    transition: 0,3s;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;


    img{
      display: block;
      width: 60px;
    }
  }
}


@media only screen and (max-width: 575px) {
  .cont-btn-lang-cert{
  
    .btn-lang-cert{
      border-radius: 12px;
      text-decoration: none;
      transition: 0,3s;
      padding: 8px 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: unset;
    }
  }
}